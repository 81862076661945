<template>
    <div class="container-fluid d-flex flex-column flex-grow-1 mt-3">
        <div class="animated fadeIn">
            <b-row>
                <b-col lg="12">
                    <transition name="fade">
                        <b-card no-body>
                            <div slot="header">
                                <i class="fa fa-edit"></i> {{ pageName }}
                                <div class="card-header-actions">
                                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                                        <i class="icon-arrow-up"></i>
                                    </b-link>
                                </div>
                            </div>
                            <b-collapse id="collapse1" visible>
                                <b-card-body>
                                    <b-row>
                                        <b-col sm="6">
                                            <b-row>
                                                <b-col sm="6">
                                                    <TextBox v-model="item.competitionName" placeholder="Name"
                                                             :addClass="{'is-invalid': $v.item.competitionName.$error}">Name
                                                    </TextBox>

                                                </b-col>
                                                <b-col sm="6">
                                                    <DatePicker v-model="item.endDate" placeholder="Name">End date
                                                    </DatePicker>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col sm="6">
                                                    <TextBox v-model="item.whenActive" placeholder="Name"
                                                    >When active (attempt count for this competition active)
                                                    </TextBox>

                                                </b-col>
                                                <b-col sm="6">
                                                    <fieldset class="form-group">
                                                        <label>Active </label>
                                                        <div role="group">
                                                            <input type="checkbox" id="checkbox"
                                                                   v-model="item.active">
                                                        </div>
                                                    </fieldset>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col sm="6">
                                            <table class="table table-bordered table-sm">
                                                <tbody>
                                                <tr>
                                                    <td>Channel Name</td>
                                                    <td>Channel Id</td>
                                                    <td>Action</td>
                                                </tr>
                                                <tr v-for="(channel, index) in item.channels" :key="index">
                                                    <td>
                                                        <TextBox v-model="channel.channelName"/>
                                                    </td>
                                                    <td>
                                                        <TextBox v-model="channel.channelId"/>
                                                    </td>
                                                    <td class="text-center"
                                                        style="vertical-align: middle; width: 70px;">
                                                        <b-button variant="success" type="button" size="sm"
                                                                  @click="addRow(index)">
                                                            <i class="fa fa-plus"></i>
                                                        </b-button>
                                                        <b-button variant="danger" type="button" size="sm"
                                                                  @click="removeRow(index)">
                                                            <i class="fa fa-window-close"></i>
                                                        </b-button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </b-col>
                                    </b-row>
                                    <div class="d-flex justify-content-end">
                                        <div class="form-actions">
                                            <b-button right type="submit" variant="primary" @click="onSubmit">Save
                                            </b-button>
                                            <router-link type="cancel" class="btn btn-secondary" variant="secondary"
                                                         :to="'/competitions'">Cancel
                                            </router-link>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </transition>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
  import {required} from 'vuelidate/lib/validators';

  export default {
        name: "CompetitionsAddEditPage",
        data() {
            return {
                item: {
                    competitionName: '',
                    channels: [{channelName: '', channelId: ''}]
                },
            }
        },
        created() {
            if (!this.$route.params.id || this.$route.params.id <= 0) {
                return
            }
            this.axios.get('competition/item', {params: {id: this.$route.params.id}})
                .then(response => {
                    this.item = response.data.data
                })
                .catch(error => {
                    if (error.response && error.response.data.status === 400) {
                        this.$toastr.error(error.response.data.message)
                    }
                })
        },

        computed: {
            pageName() {
                return this.$route.params.id ? 'Edit Competition' : 'Add Competition'
            }
        },
        methods: {
            onSubmit() {
                if (this.$v.item.$invalid) {
                    this.$v.$touch()
                    return
                }
                let axios
                if (this.item.id) {
                    axios = this.axios.put('competition/item', this.item)
                } else {
                    axios = this.axios.post('competition/item', this.item)
                }

                axios.then((res) => {
                    if (res.data) {
                        this.$toastr.info('Successfully saved')
                        this.$router.push('/competitions')
                    } else {
                        this.$toastr.error(res.data.message)
                    }
                })
            },
            removeRow(channelIntex) {
                if (this.item.channels.length <= 1) {
                    this.$toastr.error("You can't delete last row")
                    return
                }
                const _compItem = this.item
                const itemIndex = _compItem.channels[channelIntex]
                if (itemIndex) {
                    _compItem.channels.splice(channelIntex, 1)
                    this.item = _compItem
                }
            },
            addRow(channelIntex) {
                const _compItem = this.item
                _compItem.channels = [
                    ..._compItem.channels.slice(0, channelIntex + 1),
                    Object.assign({}, this.filterItem),
                    ..._compItem.channels.slice(channelIntex + 1)
                ]
                this.item = _compItem
            }
        },
        validations: {
            item: {
                competitionName: {
                    required
                }
            }
        }
    }
</script>

<style scoped>

</style>